<template>
  <div>
    <div class="pa-3">
      <v-row>
        <v-col cols="12">
          <v-checkbox
            filled
            dense
            hide-details="auto"
            v-model="opcionesAutoestudio.aplicar_iva"
            label="Aplicar IVA"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>

    <v-dialog :value="error" max-width="500">
      <v-card>
        <v-alert class="ma-0" type="error" outlined text>
          {{ error?.message }}
        </v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { downloadFile } from "@/utils/index.js";
import { useVModel } from "@vueuse/core";
import { ref, watch } from "vue";
import axios from "@/utils/axiosWrapper";

const props = defineProps({
  idEstudio: Number,
  estudio: Object,
  value: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits([
  "update:creatingAutoestudio",
  "update:value",
  "reload",
]);

const opcionesAutoestudio = useVModel(props, "value", emit);

// opcionesAutoestudio.value = {
//   aplicar_iva: true,
//   ...opcionesAutoestudio.value
// }
opcionesAutoestudio.value['aplicar_iva'] ??= true

const {
  data,
  error,
  pending: creatingAutoestudio,
  refresh: crearEstudio,
} = axios({
  method: "POST",
  url: `${process.env.VUE_APP_API_URL}/autoestudios/${props.idEstudio}`,
  responseType: "blob",
  headers: {
    "Content-Type": "multipart/form-data",
  },
  lazy: true,
  block: true,
  transformError : async (err) => {
    return JSON.parse(await err.response.data.text()).detail;
  }
});

watch(creatingAutoestudio, (v) => emit("update:creatingAutoestudio", v));

async function estudioMovilidad() {
  emit("update:creatingAutoestudio", true);
  error.value = null;

  const formData = new FormData();
  formData.append(
    "data",
    JSON.stringify({
      tipo: "estudio_movilidad_electrica",
      ...opcionesAutoestudio.value,
    })
  );

  await crearEstudio({ data: formData, block: true });

  if (error.value) {
  } else {
    downloadFile(
      new File([data.value], `estudio_movilidad_${props.idEstudio}.pdf`, {
        type: "application/pdf",
      }),
      true
    );
  }

  emit("reload");
}

defineExpose({ estudioMovilidad })
</script>

<style></style>
